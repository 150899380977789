<template>
    <div class="correct">
        <div class="title">绑定成功,你可以去小程序开启微信通知了</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                token: ''
            }
        },
        mounted () {
            // location.href = 'weixin://dl/business/?t=Oq1DAgKuYsi';
            const that = this
            that.$axios({
                url: 'https://car.lanlitao.net/getSchemeCode',
                method: 'GET',
            }).then(res => {
                console.log(res)
                location.href = res.data.data.openlink
            })
        },
    }
</script>

<style scoped>
.correct {
    padding-top: 40vh;
}
</style>